<template>
  <div class="settings-section">
    <!-- listSettings Table -->
    <table v-if="listSettings.length > 0" class="settings-table">
      <thead>
        <tr>
          <th>Settings name</th>
          <th>Current/Latest value</th>
          <th>New value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="setting in listSettings" :key="setting.key">
          <td>{{ setting.name }}</td>
          <td>{{ setting.current_value }}</td>
          <td>
            <input v-model.number="newSettingsData[setting.key]" :type="setting.type" :min="0" :max="255" />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Submit Button to update the settings, only visible when table has data -->
    <div v-if="listSettings.length > 0" class="button-container">
      <button @click="updateSettings" class="back-button">Update</button>
    </div>

    <!-- Display message if no settings are found -->
    <p v-else>{{ $t('device_data.no_logs_found') }}</p>

  </div>
</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    },
    macAddress: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      intervalId: null,
      listSettings: [], // Store list of settings fetched from API
      newSettingsData: {}
    };
  },
  async mounted() {
    await this.fetchSettingsForDevice();
    this.newSettingsData = {};
    this.listSettings.forEach(setting => {
      this.newSettingsData[setting.key] = setting.current_value;
    });
    this.intervalId = setInterval(this.fetchSettingsForDevice, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },

    /**
     * Fetches logs for a specified device from the backend API.
     *
     * @returns {Promise<void>} A promise that resolves when the logs are successfully fetched and stored.
     */
    async fetchSettingsForDevice() {
      try {
        // Update Keycloak token
        await keycloak.updateToken(30);
        const token = keycloak.token;

        // Check if the token is available
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        // Make an API call to fetch logs
        const response = await axios.get(
          `${backendUrl}/api/maintenance/settings`,
          {
            params: { id: this.deviceId }, // Pass deviceId as a query parameter
            headers: { Authorization: `Bearer ${token}` } // Include token in the headers
          }
        );

        // Check if the request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Validate response data
        if (!response.data || !response.data.data) {
          this.showError(this.$t('general_errors.response_invalid_structure'));
          return;
        }

        if (!response.data.success) {
          this.showError(this.$t('general_errors.response_with_error'));
          return;
        }

        // Store the fetched logs in the component's state
        this.listSettings = response.data.data; // Assuming logs is a data property in your component

      } catch (error) {
        console.error(`fetchSettingsForDevice: ${error}`); // Log the error for debugging
        this.showError(this.$t('general_errors.response_internal_error'));
      }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString();
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString();
    },
    async updateSettings() {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token; // Get Keycloak token for authorization

        if (!token) {
          console.error(`Authorization token is missing. Please log in.`);
          return;
        }
        console.log(JSON.stringify(this.newSettingsData));
        const response = await axios.post(
          `${backendUrl}/api/maintenance/settings/update`,
          {
            settings_data: this.newSettingsData,
            id: this.macAddress
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // Check if response status is successful
        if (response.status !== 200) {
          console.log(`Failed to update device`);
          return;
        }

        // Return the fetched logs data
        return response.data;

      } catch (error) {
        // Improve error handling to capture specific issues
        console.error(`Error updating settings for device ${this.macAddress}:`, error.message);
        return;
      }
    },
  }
};
</script>

<style scoped>
.settings-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.settings-table th,
.settings-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.settings-table th {
  background-color: #179b0b;
  color: white;
}

.settings-table tr:hover {
  background-color: #f1f1f1;
}

.settings-table td {
  color: #333;
}

.settings-section p {
  color: #7f8c8d;
  font-style: italic;
}

/* Container to hold the button in the bottom-right corner */
.button-container {
  display: flex;
  justify-content: flex-end; /* Push the button to the right */
  padding: 20px 0; /* Add space around the button */
}

/* Existing button styling */
.back-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #179b0b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Spacing for the icon */
.back-button i {
  margin-right: 8px;
}

/* Hover effect */
.back-button:hover {
  background-color: #1f7917;
}

/* Focus effect */
.back-button:focus {
  outline: none;
}

/* Active effect */
.back-button:active {
  background-color: #1e8015;
}
</style>
